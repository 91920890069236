import {
	Row,
	Col,
	Card,
	Button,
	Form,
	FormControl,
	Alert,
	Image
} from 'react-bootstrap'
import InputGroup  from 'react-bootstrap/InputGroup'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faEnvelope, faLock, faEyeSlash, faEye } from '@fortawesome/free-solid-svg-icons'
import Link from 'next/link'
import React, { useState, useContext } from 'react'
import axios from 'axios'
import { useRouter } from 'next/router'
import { serverSideTranslations } from 'next-i18next/serverSideTranslations'
import { useTranslation } from 'next-i18next'
import dayjs from 'dayjs'

import UserContext from '../UserContext'

const Login: React.FC = () => {
	const { t } = useTranslation()
	const [credentials, setCredentials] = useState({
		emailAddr: '',
		password: ''
	})
	const [show, setShow] = useState(false)
	const [showPass, setShowPass] = useState(false)

	const { setUser } = useContext(UserContext)

	const router = useRouter()
	const source = router.query.source

	const handleSubmit = async (event): Promise<void> => {
		event.preventDefault()
		event.stopPropagation()
		const res = await axios.post(process.env.NEXT_PUBLIC_LOGIN_ROUTE as string, credentials)
		const obj = res.data
		if (obj.applicant !== null) {
			// Authenticated
			// Store JWT in localStorage(Ignore the other ways first ...)
			window.localStorage.setItem('token', obj.token)
			window.localStorage.setItem('profile', JSON.stringify(obj.applicant))

			window.localStorage.setItem('login-date', JSON.stringify(dayjs()))
			// set Context
			setUser(obj.applicant)
			// Redirect to Root page
			if (typeof router.query.redirect !== 'undefined') {
				const isApplyJob = router.query.redirect?.slice(0,4) === '/job'
				if (isApplyJob) {
					const isFromEvent = router.query.redirect?.includes('event=')
					const applyJobLink = `${router.query.redirect}${isFromEvent ? '&apply=true' :'?apply=true'}`
					router.push(applyJobLink)
				} else {
					router.push(router.query.redirect as string).catch(() => null)
				}
			} else {
				if(source === 'broadcast') {
					router.push('/broadcast-success').catch(() => null)
				} else {
					router.push('/').catch(() => null)
				}
			}
		} else {
			// Show Alert
			setShow(true)
		}
	}

	return (
		<Card>
			<Card.Body>
				<Row>
					<Col md={6} xs={12} className='login-form-col'>
						<h2 className='text-center mb-5'>{t('login.login')}</h2>
						<Form onSubmit={handleSubmit}>
							<InputGroup>
								<InputGroup.Text style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', backgroundColor: 'white', borderRight: '0px', borderColor: '#EBEBEB' }}>
									<FontAwesomeIcon icon={faEnvelope} />
								</InputGroup.Text>
								<FormControl
									size='lg'
									placeholder={t('login.email_address')}
									style={{ borderTopRightRadius: '10px', borderBottomRightRadius: '10px', borderLeft: '0px', borderColor: '#EBEBEB' }}
									type='email'
									value={credentials.emailAddr}
									onChange={event => {
										setCredentials({
											...credentials,
											emailAddr: event.target.value
										})
									}}
									required
									pattern='[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$'
									title='Please enter a valid email address.'
								/>
							</InputGroup>

							<br />
							<InputGroup>
								<InputGroup.Text style={{ borderTopLeftRadius: '10px', borderBottomLeftRadius: '10px', backgroundColor: 'white', borderRight: '0px', borderColor: '#EBEBEB' }}>
									<FontAwesomeIcon icon={faLock} />
								</InputGroup.Text>
								<FormControl
									size='lg'
									placeholder={t('login.password')}
									style={{ borderLeft: '0px', borderColor: '#EBEBEB' }}
									type={showPass ? 'text' : 'password'}
									value={credentials.password}
									onChange={event => {
										setCredentials({
											...credentials,
											password: event.target.value
										})
									}}
									required
									pattern='.{8,}'
								/>

								<InputGroup.Text style={{ backgroundColor: 'white', borderBottomRightRadius: '10px', borderLeft: '0px', borderTopRightRadius: '10px', borderColor: '#EBEBEB' }}>
									{showPass ? <FontAwesomeIcon icon={faEyeSlash} onClick={() => setShowPass(false)} /> : <FontAwesomeIcon icon={faEye} onClick={() => setShowPass(true)} />}
								</InputGroup.Text>

							</InputGroup>
							{show && (
								<Alert
									variant='danger'
									onClose={() => setShow(false)}
									dismissible
									style={{ marginBottom: '0', marginTop: '.5rem' }}
								>
									{t('login.wrong_email_or_password')}
								</Alert>
							)}

							<Row>
								<Col style={{ textAlign: 'right', marginTop: '5px', marginBottom: '1em' }}>
									<Link href='/forget-password' passHref>
										<Button className='text-decoration-none' variant='link'>{t('login.forgot_password')}</Button>
									</Link>
								</Col>
							</Row>

							<br />
							<Button className='text-white' variant='primary' type='submit' size='lg' style={{ width: '100%', borderRadius: '10px', marginBottom: '2em' }}>
								{t('login.login')}
							</Button>
							<br /><br />
						</Form>
						<hr className='d-block d-sm-none' />
					</Col>
					<Col md={6} xs={12} className='login-form-col text-center'>
						<br />
						<h2 className='mb-4'>{t('login.account_not_existed')}</h2>
						<br />
						<Image src='/img/login.png' alt='login logo' />
						<br /><br />
						<Link href={typeof router.query.redirect !== 'undefined' ? `/register?redirect=${router.query.redirect as string}` : '/register'} passHref>
							<Button
								className='text-white mt-5'
								variant='primary'
								size='lg'
								style={{ width: '100%', borderRadius: '10px', marginTop: '3em' }}
							>
								{t('register.register_now')}
							</Button>
						</Link>
					</Col>
				</Row>
			</Card.Body>
		</Card>
	)
}

export async function getStaticProps ({ locale }) {
	return {
		props: {
			...(await serverSideTranslations(locale, ['common']))
			// Will be passed to the page component as props
		}
	}
}

export default Login
